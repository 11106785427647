@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  padding: 0;
  margin: 0;
  height:100%;
  width: 100%;
  overflow:hidden;
}

body {
  height: 100%;
  font-family: 'Nunito', sans-serif;
}

.app {
  position: absolute;
  width: 60vw;
  height: 100%;
  left: 50%;
  transform: translateX(-50%); 
}

.content {
  width:100%;
}

.menu-bar {
  position: absolute;
  top:20px;
  display: flex; 
  list-style-type: none;
  width: 100%;
  text-decoration: none;
  text-align: center;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0px;
  margin: 0;
  height:30px;
  z-index: 1;
}

.menu-bar li {
  width: 96px;
}

.menu-item {
  text-decoration: none;
  color:black;
}

.push-menu {
  margin-left: auto;
}

.menu-text {
  text-decoration: none;
  color:black;
}

.menu-text:hover {
  color: gray;
}

.menu-logo img{
  height:30px;
  padding: 10px 0px;
}

.current-menu {
  text-decoration: underline;
}

.content {
  margin-top: 70px;  
  height:100%;
}

.cta-button {
  background-color: green;
  border: none;
  margin: 0;
  margin-top: 20px;
  padding: 15px;
  border-radius: 10px;
  display: inline-block;
  margin-right: 5px;
  transition-duration: 250ms;
  font-weight: 700;
}

.cta-button a {
  color:white;
  text-decoration: none;
  font-size: 15px;
}

.cta-button:hover {
  transform: scale(1.1);
  transition-duration: 250ms;
}

.button-arrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-left:5px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.footer {
  position: fixed;
  width: 100%;
  bottom: 0px;
  background-color: white;
}

.footer-text {
  margin: 0 auto;
  text-align: center;
  padding-bottom: 10px;
  font-size: 10px;
  color:white;
  filter: invert(60%);
}

.footer-logo-container {
  position: relative;
  display: flex;
  justify-content: center;
}

.footer-logo {
  height: 30px;
  filter: invert(60%);
  margin: 0px 5px;
}

hr {
  border: none;
  background-color:lightgray;
  height:2px;
  width:100%;
}

/* VECTOR CHARACTER CLASSES */
.animated-container {
  position: relative;
  height: 100vh;
}

.char-body {
  width:40vw;
  height:70vh;
  position: absolute;
  left:50vw;
  top:50%;
  transform: translate(-50%,-50%);
}

.char-head {
  position: absolute;
  top: 5px;
  left:25px;
  z-index: 2;
}

.char-lower-body {
  position: absolute;
  top:380px;
}

.char-upper-body {
  position: absolute;
  top:140px;
  left:-40px;
}

.char-arm {
  width:50vw;
  height: 600px;
  position: absolute;
  top:50%;
  transform: translateY(-50%);
}

.char-left-hand {
  position: absolute;
  left:260px;
  top:190px;
  z-index: -3;
}

.char-left-lower-arm {
  position: absolute;
  left:160px;
  top:228px;
  z-index: -2;
}

.char-left-upper-arm {
  position: absolute;
  left:80px;
  top: 147px;
  z-index: -1;
}

.char-shadow {
  height:50px;
  width: 250px;
  border-radius: 50%;
  background-color: gray;
  position: absolute;
  bottom: 170px;
  left:-28px;
  z-index: -2;
}


/* Char 2 Easy Version */
.char-container {
  position: fixed;
  left:50%;
  top:50%;
  transform: translate(-50%,-50%);
}

.character {
  height:60vh;
}

.shadow-container {
  position: relative;
  width: 100vw;
  left:50%;
  top:50%;
  transform: translate(-50%,-60%);
  z-index: -2;
  overflow: hidden;
  height:100vh;
}

.back-shadow {
  position: absolute;
  left:50%;
  top:50%;
  transform: translate(-50%,-60%);
  height:50vh;
  width: 900px;
  border-radius: 50%;
  background-color: #FAF3DD;
}

.home-content {
  font-weight: 700;
  font-size: 2rem;
  background-color: rgba(196, 196, 196, 0.60);;
  padding:30px;
  width: 40vw;
  position: absolute;
  left:50%;
  top:50%;
  transform: translate(-50%,-30%);
  z-index: 2;
  text-align: center;
}

/* RESUME */
.pdf-embed {
  width:100%;
  height:80%;
}

/* PROJECTS */
.project-labels {
  text-align: center;
  margin-top:20px 0px;
}

.project-labels button {
  margin:5px;
  background-color: black;
  border-radius: 5px;
  padding: 3px;
  text-decoration: none;
  color:white;
  cursor: pointer;
  border:none;
  padding: 5px 10px;
  margin-top:5px;
}

.portfolio-container {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: flex-start;
  position: absolute;
  left:50%;
  transform: translate(-50%,0);
  margin-top: 20px;
  height: 70vh;
  overflow-y:auto;
}

.portfolio-item {
  position: relative;
  width:200px;
  height:200px;
  border-radius: 20px;
  margin: 10px;
  -webkit-box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.1); 
  box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.1);
}

.portfolio-item-title {
  color:white;
  font-weight: 700;
  transition-duration: 250ms;
  padding:15px;
}

.portfolio-item-status {
  color:white;
  position: absolute;
  padding:5px 10px;
  border-radius: 10px;
  margin:10px;
  bottom:0;
  font-size: 12px;
}

.portfolio-item-page {
  position: fixed;
  height:100vh;
  width:100vw;
  z-index: 1000;
}

.portfolio-item-page-overlay {
  background-color: rgba(0,0,0,0.1);
}

.portfolio-item-overlay {
  background: linear-gradient(360deg, rgba(255, 255, 255, 0.02) 50%, rgba(0, 0, 0, 0.7) 100%);
  height:100%;
  border-radius: 20px;
}

.portfolio-item-overlay:hover .portfolio-item-title{
  transform: scale(1.05);
  transition-duration: 250ms;
}

.portfolio-item-overlay:hover {
  cursor: pointer;
}

/*PROJECT PAGE*/
.project-page {
  position: relative;
  width: 100%;
  overflow-y: scroll;
  padding-bottom: 60px;
  height:80vh;
}

.project-back {
  background-color: black;
  color: white;
  text-decoration: none;
  border-radius: 10px;
  margin-top: 10px;
  padding: 0 8px;
}

.back-arrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-left:5px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.project-name {
  margin-top:10px;
  font-size:30px;
  font-weight: 700;
}

.project-images {
  display: flex;
  align-content: flex-start;
  justify-content: center;
  flex-flow: row wrap;
  overflow-y: scroll;
  overflow-x: hidden;
  width:50vw;
  margin: 0 auto;
  height:40vh;
  margin-top: 20px;
}

.project-image {
  width:100%;
  margin:5px;
}

.project-heading {
  font-size: 20px;
  font-weight: 700;
}

.project-block {
  margin: 20px 0px;
}

.project-list-item {
  list-style-type: circle;
}

@media only screen and (max-width: 1000px) {
  .home-content {
    font-size: 1.2rem;
  }
  .project-labels {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
  }
  .project-labels button {
    margin: 1px 5px;
  }

}

@media only screen and (max-width: 420px) {
  .portfolio-item {
    width: 60vw;
    height: 60vw;
  }
  .menu-text {
    font-size: 0.7rem;
  }
}